
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        icon: {
            type: Boolean,
            default: false,
        },

        height: {
            type: Number,
            default: 0,
        },
    },

    computed: {
        dimensions() {
            if (this.icon) {
                const DEFAULT_ICON_SIZE = 142;
                const square = this.height || DEFAULT_ICON_SIZE;
                return { height: square, width: square, style: { width: 'auto' } };
            } else {
                const DEFAULT_HEIGHT = 142;
                const DEFAULT_WIDTH = 509;
                const height = this.height || DEFAULT_HEIGHT;
                const width = DEFAULT_WIDTH * height / DEFAULT_HEIGHT;
                return { height, width, style: { width: 'auto' } };
            }
        },
    },
});
