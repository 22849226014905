
import { defineComponent } from 'vue';

export default defineComponent({
    inject: ['actualHeadingLevel', 'visualHeadingLevel'],

    props: {
        small: { type: Boolean, default: false },
    },

    computed: {
        tag(): `h${number}` {
            // @ts-ignore
            return `h${this.actualHeadingLevel}`;
        },

        looksLike(): `h${number}` {
            // @ts-ignore
            return `h${this.visualHeadingLevel}`;
        }
    },
});
