
import Vue from '@/vueTyped';

export default Vue.extend({
    props: {
        tag: { type: String, default: 'div' },
    },

    computed: {
        layoutClasses() {
            return {
                inset: 'page-layout-inset',
                section: 'page-layout-section',
                stack: 'page-layout-stack',
            };
        },
    },
});
