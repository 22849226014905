
import Vue from '@/vueTyped';
import { Investigation, Post, Project } from '@/types';
import orderBy from 'lodash/orderBy';
import FourOhFour from '@/pages/404.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import MarkdownOutput from '@/components/MarkdownOutput.vue';
import PrimaryLogo from '@/components/logo/Primary.vue';
import RegistrationForm from '@/components/RegistrationForm.vue';
import { MetaInfo } from 'vue-meta';
import RouteNames from '@/router/names';
import UniversalImage from '@/components/UniversalImage.vue';
import getShareProps from './get-share-props';

export default Vue.extend({
    components: {
        FourOhFour,
        LoadingIndicator,
        MarkdownOutput,
        PrimaryLogo,
        RegistrationForm,
        UniversalImage,
    },

    metaInfo(): MetaInfo {
        return {
            title: this.project ? `${this.project.projectDetails.title} (with ${this.project.clientGroup.name})` : '',
        };
    },

    i18n: {
        messages: {
            en: {
                joinHeadline: 'Join the ISeeChange community',
                helpUsUnderstand: 'Help us understand',
                howToHelp: 'How to start helping',
                howItWorksHeadline: 'How ISeeChange Works',
                addVoice: 'Add your voice',
                impactCommunity: 'Impact your community',
                anyTime: 'Participate any time',
                topicsHeadline: 'Topics',
                postsHeadline: 'Check out these sightings for inspiration',
                partnersHeadline: 'Additional partners',
                spreadTheWord: 'Help spread the word',
            },

            es: {
                joinHeadline: 'Únete a la comunidad de ISeeChange',
                helpUsUnderstand: 'Ayúdanos a entender',
                howToHelp: 'Cómo empezar a ayudar',
                howItWorksHeadline: 'Cómo funciona ISeeChange',
                addVoice: 'Suma tu voz',
                impactCommunity: 'Influye en tu comunidad',
                anyTime: 'Participa en cualquier momento',
                topicsHeadline: 'Temas',
                postsHeadline: 'Consulte estos avistamientos para inspirarse',
                partnersHeadline: 'Otros participantes',
                spreadTheWord: 'Ayude a difundir el mensaje',
            },
        },
    },

    data() {
        return {
            loading: 0,
            project: null as Project | null,
            scrollObserver: null as IntersectionObserver | null,
            formIsVisible: true,
            fetchingPosts: false,
            posts: [] as Post[],
        };
    },

    computed: {
        projectInvestigations(): Investigation[] {
            return this.$store.state.investigations.items.filter(investigation => {
                return this.project?.investigations.find(projectInvestigation => {
                    return investigation.id === projectInvestigation.id;
                });
            });
        },

        shareProps(): object {
            return this.project ? getShareProps(this.project) : {};
        },
    },

    watch: {
        '$route.path': {
            immediate: true,
            async handler() {
                try {
                    this.loading += 1;
                    const { organizationSlug, projectSlug } = this.$route.params;
                    const { data: project, error } = await this.$store.dispatch('fetchProject', { groupSlug: organizationSlug, slug: projectSlug });
                    if (error) throw error;
                    this.project = project;
                } catch (error) {
                    console.error(error);
                } finally {
                    this.loading -= 1;
                }
            }
        },

        project: {
            immediate: true,
            handler(project) {
                if (project) {
                    this.fetchPosts();
                }
            }
        },
    },

    methods: {
        handleRegistrationFormMounted() {
            const navHeight = getComputedStyle(document.documentElement).getPropertyValue('--webnavigation--height');
            this.scrollObserver = new IntersectionObserver(this.handleIntersectionChange, { rootMargin: `-${navHeight.trim()} 0px` });
            this.scrollObserver.observe(this.$refs.registrationForm as Element);
        },

        handleRegistrationFormDestroy() {
            this.scrollObserver?.disconnect();
            this.scrollObserver = null;
        },

        handleIntersectionChange(entries: IntersectionObserverEntry[]) {
            const { intersectionRatio } = entries[0];
            this.formIsVisible = intersectionRatio !== 0;
        },

        async fetchPosts() {
            if (!this.project) return;

            this.fetchingPosts = true;
            const fetchingPostsFor = this.project.id;

            const posts: Post[] = await Promise.all(this.project.projectDetails.samplePosts.map(async (id: string) => {
                const { data } = await this.$store.dispatch('fetchPostById', { id });
                return data;
            }));

            const projectHasntChanged = this.project.id === fetchingPostsFor;
            if (projectHasntChanged) {
                this.posts = posts.filter(Boolean);

                for (const post of this.posts) {
                    post.photoObjs = orderBy(post.photoObjs, 'created_at') as Post['photoObjs'];
                }
            }

            this.fetchingPosts = false;
        },

        scrollToRegistrationForm() {
            (this.$refs.registrationForm as Element).scrollIntoView({ behavior: 'smooth' });
        },

        handleRegistrationSuccess() {
            this.$router.push({
                name: RouteNames.REGISTER,
                query: {
                    redirect: this.$router.resolve({
                        name: RouteNames.REGISTER,
                        query: {
                            ...this.$route.query,
                            show: 'apps',
                        }
                    }).href,
                },
            });
        },
    },
});
