
import { defineComponent } from 'vue';

export default defineComponent({
    inject: {
        priorActualLevel: { from: 'actualHeadingLevel', default: 0 },
        priorVisualLevel: { from: 'visualHeadingLevel', default: 0 },
    },

    provide() {
        return {
            // @ts-ignore
            actualHeadingLevel: this.priorActualLevel + 1,
            // @ts-ignore
            visualHeadingLevel: this.priorVisualLevel + 1 + this.visualModifier,
        };
    },

    props: {
        tag: { type: [String, Function], default: 'div' },
        visualModifier: { type: Number, default: 0 },
    },
});
