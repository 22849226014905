
import ProjectIndexCard from './IndexCard.vue';
import { ClientGroup, Project } from '@/types';
import Vue from '@/vueTyped';
import orderBy from 'lodash/orderBy';
import BaseMenu from '@/components/BaseMenu.vue';
import { unindent } from '@/util.app';
import MarkdownOutput from '@/components/MarkdownOutput.vue';
import turfDistance from '@turf/distance';

export default Vue.extend({
    metaInfo(): any {
        return {
            title: this.$t('title'),
        };
    },

    i18n: {
        messages: {
            en: {
                title: 'Projects',
                intro: unindent(`
                    With ISeeChange Projects, local communities and partners work together to study and address climate challenges in their own neighborhoods and cities. Projects are typically led by cities, engineers, educators, researchers, and local organizations. They build infrastructure, conduct research, create solutions, increase climate literacy and more.

                    Do you have a project idea? [Tell us about it >](https://forms.gle/iqW93xa8H11f4GbD7)
                    Want to share ISC with local decision makers and orgs? [Use this toolkit >](https://drive.google.com/drive/folders/1PMkBXukvCwqbkflxlyEkk7dhgZr-meH3?usp=sharing)
                `)
            },

            es: {
                title: 'Proyectos',
                intro: unindent(`
                    Con los proyectos ISeeChange, las comunidades locales y los socios trabajan juntos para estudiar y abordar los retos climáticos en sus propios barrios y ciudades. Los proyectos suelen estar dirigidos por ciudades, ingenieros, educadores, investigadores y organizaciones locales. Construyen infraestructuras, llevan a cabo investigaciones, crean soluciones, aumentan los conocimientos sobre el clima y mucho más.

                    ¿Tiene una idea de proyecto? [Cuéntanoslo >](https://forms.gle/iqW93xa8H11f4GbD7)
                    ¿Quieres compartir el ISC con los responsables locales y las organizaciones? [Utiliza este kit de herramientas >](https://drive.google.com/drive/folders/1PMkBXukvCwqbkflxlyEkk7dhgZr-meH3?usp=sharing)
                `)
            },
        },
    },

    components: {
        ProjectIndexCard,
        BaseMenu,
        MarkdownOutput,
    },

    data() {
        return {
            projects: [] as Project[],
            LOCAL_THRESHOLD: 100, // kilometers,
        };
    },

    computed: {
        clientGroups(): ClientGroup[] {
            return this.$store.state.account.currentUser?.clientGroups ?? [];
        },

        userCanCreateProjects(): boolean {
            // `isInternal` groups are ISeeChange-only.
            // City of Miami has special access.
            const userIsISeeChange = this.clientGroups.some(group => group.isInternal);
            const userIsCityOfMiami = this.clientGroups.some(group => group.slug === 'city-of-miami');
            return userIsISeeChange || userIsCityOfMiami;
        },

        projectDistances(): Record<Project['id'], number> {
            const distances: Record<Project['id'], number> = {};

            const userPoint = this.$store.state.account.currentUser?.geoPoint;
            if (!userPoint) return distances;

            for (const project of this.projects) {
                const projectCenter = project.regions[0]?.centerGeoPointGeoJson;
                if (!projectCenter) continue;
                distances[project.id] = turfDistance(userPoint.coordinates, projectCenter.coordinates);
                // project._debugDistance = distances[project.id];
            }

            return distances;
        },

        projectsSortedByProximity(): Project[] {
            return [...this.projects].sort((p1, p2) => {
                const p1Distance = this.projectDistances[p1.id];
                const p2Distance = this.projectDistances[p2.id];
                if (p1Distance && !p2Distance) return -1;
                if (!p1Distance && p2Distance) return +1;
                if (!p1Distance && !p2Distance) return 0;
                return Math.sign(p1Distance - p2Distance);
            });
        },
    },

    async mounted() {
        const fetchResponse = await this.$store.dispatch('fetchProjects');
        this.projects = orderBy(fetchResponse.data, p => p.projectDetails.title.toLowerCase());
    },

    methods: {
        async createNewProject(clientGroupId: ClientGroup['id']) {
            const clientGroup = this.clientGroups.find(group => String(group.id) === String(clientGroupId));

            if (!clientGroup) {
                throw new Error(`Couldn't find user's'group "${clientGroupId}" when creating a project`);
            }

            const slug = Math.random().toString(16).split('.')[1];

            const words = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. '.repeat(10).split(/\s+/);
            const random = (length: number) => words.sort(() => Math.random() - 0.5).slice(0, length).join(' ');

            const response = await this.$store.state.apiClient.post('/local-projects', {
                clientGroupId: clientGroup.id,
                projectDetails: {
                    goals: random(20),
                    image: null,
                    instructions: random(20),
                    introduction: random(20),
                    partners: [{
                        name: random(4),
                        url: 'https://www.example.com',
                        logo: null,
                        infoShared: false,
                    }],
                    prompts: Array(3).fill(null).map(() => random(15)),
                    published: false,
                    samplePosts: [],
                    share_precise_location: false,
                    slug,
                    title: `New project for ${clientGroup.name}`,
                },
            });

            const project = response.data;

            this.projects.unshift(project);

            this.$router.push({
                name: 'project',
                params: {
                    organizationSlug: project.clientGroup.slug,
                    projectSlug: project.projectDetails.slug
                }
            });
        },
    },
});
