
import { apiClient } from '@/api-client';
import ImageInput from '@/components/ImageInput.vue';
import MapBase from '@/components/MapBase.vue';
import MapLayer from '@/components/MapLayer.vue';
import UniversalImage from '@/components/UniversalImage.vue';
import { CLIENT_ORIGIN } from '@/config';
import OverlayModal from '@/layouts/OverlayModal.vue';
import { ClientGroup, CurrentUser, Investigation, Project, Region } from '@/types';
import Heading from '@/ui/Heading.vue';
import HeadingLevel from '@/ui/HeadingLevel.vue';
import LayoutColumn from '@/ui/LayoutColumn.vue';
import LayoutRow from '@/ui/LayoutRow.vue';
import PageLayout from '@/ui/PageLayout.vue';
import Vue from '@/vueTyped';
import DataSharingContent from './DataSharingContent.vue';
import ToggleableTextInput from './ToggleableTextInput.vue';

export default Vue.extend({
    i18n: {
        messages: {
            en: {
                controls: {
                    preview: 'Preview',
                    publish: 'Publish',
                    unpublish: 'Unpublish to edit',
                },

                addSighting: 'Add a sighting',

                byline: {
                    with: 'With',
                },

                prompts: {
                    heading: 'Help us understand',
                    add: 'Click below to add a prompt',
                },

                instructions: {
                    heading: 'How you can help',
                },

                regions: {
                    heading: 'Regions we’re looking at',
                    noneSelected: 'No regions selected',
                },

                topics: {
                    heading: 'Topics we’re interested in',
                },

                investigations: {
                    chooseClick: 'Click below to choose topics',
                    noneSelected: 'No topics selected',
                    chooseFrom: 'Choose from the following topics,',
                },

                goals: {
                    heading: 'Goals of the project',
                },

                transparency: {
                    learnMore: 'Learn more about data privacy',
                },

                share: {
                    heading: 'Share with your community',
                    body: 'Invite your friends, family, and colleagues to get involved with this project.',
                },
            },

            es: {
                addSighting: 'Añadir un avistamiento',

                byline: {
                    with: 'con',
                },

                prompts: {
                    heading: 'Ayúdanos a entender',
                },

                instructions: {
                    heading: 'Cómo puedes ayudar',
                },

                regions: {
                    heading: 'Regiones en las que nos fijamos',
                },

                topics: {
                    heading: 'Temas que nos interesan',
                },

                goals: {
                    heading: 'Goals of the project',
                },

                transparency: {
                    learnMore: 'Más información sobre la privacidad de los datos',
                },

                share: {
                    heading: 'Comparte con tu comunidad',
                    body: 'Invita a tus amigos, familiares y colegas a participar en este proyecto.',
                },
            },
        },
    },

    metaInfo(): any {
        return {
            title: this.project ? `${this.project.projectDetails.title} (with ${this.project.clientGroup.name})` : "",
        };
    },

    components: {
        DataSharingContent,
        Heading,
        HeadingLevel,
        ImageInput,
        MapBase,
        MapLayer,
        OverlayModal,
        UniversalImage,
        LayoutColumn,
        LayoutRow,
        PageLayout,
        ToggleableTextInput,
    },

    props: {
        organizationSlug: { type: String, required: true },
        projectSlug: { type: String, required: true },
    },

    data() {
        return {
            loading: 0,
            dataSharingModelOpen: false,
            sectionsBeingEdited: {
                intro: false,
                helpUs: false,
                regions: false,
                topics: false,
                goals: false,
                dataSharing: false,
            },
            topicSelectionModalOpen: false,
        };
    },

    computed: {
        watchableProjectIdentifier(): string {
            return `${this.organizationSlug} ${this.projectSlug}`;
        },

        allInvestigations(): Investigation[] {
            return this.$store.state.investigations.items;
        },

        clientGroup(): ClientGroup | null {
            const clientGroups = this.$store.state.account.currentUser?.clientGroups ?? [];
            return clientGroups.find(clientGroup => clientGroup.id === this.project?.clientGroup.id) ?? null;
        },

        project(): Project | null {
            return this.$store.getters.project(this.organizationSlug, this.projectSlug);
        },

        selectedRegion(): Project['regions'][number] | null {
            const selectedId = `${this.$route.query.region ?? this.project?.regions[0]?.id ?? ''}`;
            return this.project?.regions.find(r => r.id === selectedId) ?? null;
        },

        useSmallIntro(): boolean {
            return (this.project?.projectDetails.title.length ?? 0) > 45;
        },

        investigationImages(): Investigation['backgroundImage'][] {
            return this.project?.investigations.map(investigation => {
                const fromStore = this.$store.state.investigations.items.find(i => i.id === investigation.id);
                return fromStore?.backgroundImage;
            }).filter(Boolean) as Investigation['backgroundImage'][];
        },

        smallScreen(): boolean {
            return !this.$vuetify.breakpoint.smAndUp; // TODO: Replace this
        },

        showInvestigationsCollage(): boolean {
            const investigationsCount = (this.project?.investigations.length ?? 0);
            return investigationsCount > 1 && investigationsCount <= 5 && !this.smallScreen // && this.project?.projectDetails.investigationsCollage;
        },

        editingAnything(): boolean {
            return Object.values(this.sectionsBeingEdited).some(Boolean);
        },

        shareProps(): object {
            return {
                url: new URL(location.pathname, CLIENT_ORIGIN).href,
                title: this.project?.projectDetails.title ?? 'ISeeChange',
                hashtags: 'iseechange',
            };
        },

        canTogglePublishedState(): boolean {
            const userClientGroups: CurrentUser['clientGroups'] = this.$store.state.account.currentUser?.clientGroups ?? [];

            const userIsISeeChange = userClientGroups!.some(group => group.isInternal);
            const userIsInProjectClientGroup = userClientGroups!.find(group => group.id === this.project?.clientGroup.id);

            const userIsCityOfMiami = userClientGroups!.some(group => group.slug === 'city-of-miami');
            const projectIsCityOfMiami = this.project?.clientGroup.slug === 'city-of-miami';

            return Boolean(
                userIsInProjectClientGroup
                && (
                    userIsISeeChange
                    || (projectIsCityOfMiami && userIsCityOfMiami)
                )
            );
        },

        canEdit(): boolean {
            return this.canTogglePublishedState && !this.project?.projectDetails.published;
        },
    },

    watch: {
        watchableProjectIdentifier: {
            immediate: true,
            handler() {
                try {
                    this.loading += 1;
                    this.$store.dispatch("fetchProject", { groupSlug: this.organizationSlug, slug: this.projectSlug, skipCache: true });
                } finally {
                    this.loading -= 1;
                }
            }
        },
    },

    methods: {
        async applyChanges(changes: Record<string, unknown>) {
            this.loading += 1;
            const { error: updateError } = await this.$store.dispatch('updateProject', {
                id: this.project?.id,
                ...changes,
            });
            this.loading -= 1;

            if (updateError) {
                alert(`Error updating project: ${JSON.stringify(updateError)}`);
            }
        },

        async handleImageSelection(files: File[], detailsKey: keyof Project['projectDetails']) {
            this.loading += 1;
            const { data: photo, error: imageUploadError } = await this.$store.dispatch('uploadMedia', { file: files[0] });

            if (imageUploadError) {
                alert(`Error uploading image: ${JSON.stringify(imageUploadError)}`);
                return;
            }

            this.applyChanges({ projectDetails: { [detailsKey]: photo.id } });
            this.loading -= 1;
        },

        async handleLogoSelection(files: File[]) {
            let newPhoto;

            try {
                this.loading += 1;

                if (files.length !== 0) {
                    const imageUpload = await this.$store.dispatch('uploadMedia', { file: files[0] });

                    if (imageUpload.error) {
                        alert(`Error uploading image: ${imageUpload.error}`);
                        return;
                    } else {
                        newPhoto = imageUpload.data;
                    }
                }

                const groupUpdate = await this.$store.state.apiClient.patch(`/client-groups/${this.project!.clientGroup.id}`, {
                    logo: newPhoto?.id ?? null,
                });

                if (groupUpdate.error) {
                    alert(`Error updating client group: ${groupUpdate.error}`);
                    return;
                } else {
                    this.$set(this.project!.clientGroup, 'logo', groupUpdate.data.clientGroup.logo);
                }
            } catch (error) {
                alert(`Error updating client group logo: ${error}`);
                return;
            } finally {
                this.loading -= 1;
            }
        },

        addRegion() {
            const SHAPEFILE_EXTENSIONS = ['dbf', 'prj', 'shp', 'shx'] as const;

            const input = document.createElement('input');
            input.type = 'file';
            input.accept = SHAPEFILE_EXTENSIONS.map(ext => `.${ext}`).join(',');
            input.multiple = true;

            input.onchange = async () => {
                try {
                    const formData = new FormData();

                    const files = Array.from(input.files ?? []);
                    for (const ext of SHAPEFILE_EXTENSIONS) {
                        const dotExt = `.${ext}`;
                        const file = files.find(f => f.name.endsWith(dotExt));
                        if (file) {
                            formData.append('files', file);
                            if (ext === 'shp') {
                                formData.set('label', file.name.slice(0, -1 * dotExt.length))
                            }
                        }
                    }

                    if (!formData.has('label')) {
                        throw new Error('A .shp file is required');
                    }

                    const response = await apiClient.post('/regions', formData);

                    if (response.status >= 200 && response.status < 300) {
                        const region = response.data;
                        this.clientGroup?.regions.push(region);

                        const regionIds = this.project?.regions.map(r => r.id) ?? [];
                        regionIds.push(region.id);

                        this.saveChanges({ regions: Array.from(regionIds) });
                    } else {
                        throw new Error(`${response.statusText} ${JSON.stringify(response.data)}`);
                    }
                } catch (error) {
                    console.error(error);
                    alert(`${error}`);
                } finally {
                    input.remove();
                }
            };

            document.body.append(input);
            input.click();
        },

        async editRegionName(region: Region) {
            try {
                const newLabel = prompt('Enter the name of this region', region.label);

                if (newLabel !== null && newLabel.trim() !== '') {
                    const formData = new FormData();
                    formData.set('label', newLabel);

                    const regionUpdate = await this.$store.state.apiClient.put(`/regions/${region.id}`, formData);

                    if (regionUpdate.error) {
                        throw regionUpdate.error;
                    }

                    region.label = newLabel;
                }
            } catch (error) {
                alert(error);
            }
        },

        async removeRegion(region: Project['regions'][number]) {
            try {
                const really = confirm(`Really delete the region "${region.label}"?`);
                if (!really) return;

                const regions = new Set(this.project!.regions.map(r => r.id));
                regions.delete(region.id);

                await this.saveChanges({ regions: Array.from(regions) });

                // Reload the client group through the current user.
                await new Promise(r => setTimeout(r, 250));
                const signInCheck = await this.$store.dispatch('checkIfSignedIn');
                const me: CurrentUser | null = signInCheck.data;
                const remainingClientGroupRegionIds = me?.clientGroups?.flatMap(g => g.regions).map(r => r.id) ?? [];
                const regionInUseElsewhere = remainingClientGroupRegionIds.includes(region.id);

                if (!regionInUseElsewhere) {
                    this.$store.state.apiClient.delete(`/regions/${region.id}`);
                }
            } catch (error) {
                alert(error);
            }
        },

        async handleTopicSelection(event: SubmitEvent) {
            if (event.target instanceof HTMLFormElement) {
                const fd = new FormData(event.target);
                const topicIds = fd.getAll('investigations');
                await this.applyChanges({ investigations: topicIds });
                this.topicSelectionModalOpen = false;
            }
        },

        editNothing() {
            const keys = Object.keys(this.sectionsBeingEdited) as (keyof typeof this.sectionsBeingEdited)[];
            keys.forEach((k) => this.sectionsBeingEdited[k] = false);
        },

        async saveChanges(changes: Record<string, any>) {
            if (this.project) {
                try {
                    await this.$store.dispatch('updateProject', { id: this.project.id, ...changes });
                } catch (error) {
                    alert(error);
                    console.error(error);
                }
            }
        },
    },
});
