
import MarkdownOutput from '@/components/MarkdownOutput.vue';
import UniversalImage from '@/components/UniversalImage.vue';
import { Project } from '@/types';
import Vue from '@/vueTyped';

export default Vue.extend({
    components: {
        UniversalImage,
        MarkdownOutput,
    },

    props: {
        project: { type: Object as () => Project, required: true },
        local: { type: Boolean, default: false },
    },
});
