
import { Project } from '@/types';
import Heading from '@/ui/Heading.vue';
import HeadingLevel from '@/ui/HeadingLevel.vue';
import LayoutColumn from '@/ui/LayoutColumn.vue';
import LayoutRow from '@/ui/LayoutRow.vue';
import Vue from '@/vueTyped';

export default Vue.extend({
    i18n: {
        messages: {
            en: {
                transparency: {
                    shared: {
                        heading: 'What’s shared with',
                        andPartners: 'And partner | And partners',
                        name: 'Your full name (you can change this at anytime)',
                        postText: 'The text you add to your sightings',
                        postImages: 'Images you add to your sightings',
                        exactLocation: 'The exact location of your sightings',
                        generalLocation: 'The general location of your sightings (to the city or neighborhood level)',
                        structuredAnswers: 'Any data you add to your sightings (e.g. rain measurements, etc.)',
                        weatherData: 'External weather data synced to your sightings (the current temperature, humidity, precipitation, etc.)',
                    },

                    notShared: {
                        heading: 'What’s not shared?',
                        contactInfo: 'Your contact information',
                        homeLocation: 'Your account’s home location',
                        exactLocation: 'The exact location of your sightings',
                        otherSightings: 'Sightings unrelated to this project',
                        accountSettings: 'Your account settings',
                    },
                },
            },

            es: {
                transparency: {
                    shared: {
                        heading: 'Qué se comparte con',
                        andPartners: 'Y socio | Y socios',
                        name: 'Tu nombre completo (puedes cambiarlo en cualquier momento)',
                        postText: 'El texto que añadas a tus avistamientos',
                        postImages: 'Las imágenes que añadas a tus avistamientos',
                        exactLocation: 'La ubicación exacta de tus avistamientos',
                        generalLocation: 'La ubicación general de tus avistamientos (a nivel de ciudad o barrio)',
                        structuredAnswers: 'Cualquier dato que añadas a tus avistamientos (por ejemplo, medidas de lluvia, etc.)',
                        weatherData: 'Los datos meteorológicos externos sincronizados con sus avistamientos (la temperatura actual, la humedad, las ',
                    },

                    notShared: {
                        heading: '¿Qué no se comparte?',
                        contactInfo: 'Su información de contacto',
                        homeLocation: 'La ubicación de su cuenta',
                        exactLocation: 'La ubicación exacta de sus avistamientos',
                        otherSightings: 'Los avistamientos no relacionados con este proyecto',
                        accountSettings: 'La configuración de su cuenta',
                    },
                },
            },
        },
    },

    components: {
        Heading,
        HeadingLevel,
        LayoutRow,
        LayoutColumn
    },

    props: {
        project: { type: Object as () => Project, required: true },
        editing: { type: Boolean, default: false },
    },

    data() {
        return {
            dataSharingModelOpen: false,
        };
    },

    computed: {
        infoSharingPartners(): Project['projectDetails']['partners'] {
            // Removed in new design, but maybe they'll come back!
            // return this.project.projectDetails.partners.filter(partner => partner.infoShared);
            return [];
        },
    },
});
