
import AutoHeightTextArea from '@/components/AutoHeightTextArea.vue';
import MarkdownOutput from '@/components/MarkdownOutput.vue';

export default {
    components: {
        AutoHeightTextArea,
        MarkdownOutput,
    },

    props: {
        dismissable: { type: Boolean, default: false },
        markdown: { type: Boolean, default: true },
        readonly: { type: Boolean, default: true },
        value: { type: String, default: '' },
    },
};
