
import Vue from '@/vueTyped';

export default Vue.extend({
    name: 'FourOhFour',
    metaInfo: {
        title: 'Not Found',
    },
    computed: {

    },
});
