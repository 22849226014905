<template>
    <div class="map-base-root">
        <div v-if="loaded" class="slot-hider">
            <slot />
        </div>
    </div>
</template>

<script> // This is not TypeScript so that we can support `provide`.

import { importMapboxGl } from '@/util.mapbox';
import Vue from '@/vueTyped';

export default Vue.extend({
    provide() {
        return { parentMap: this };
    },

    props: {
        lng: { type: Number, default: -98.58 },
        lat: { type: Number, default: 39.83 },
        zoom: { type: Number, default: 3 },
        bounds: { type: Object, default: null },
        noZoomOnScroll: { type: Boolean, default: false },
    },

    data() {
        return {
            map: null,
            loaded: false,
            autozoomState: {
                queue: [],
                timeout: -1,
            },
        };
    },

    watch: {
        bounds(bounds) {
            const oldBounds = this.map?.getBounds();
            const shouldSync = bounds && String(bounds) !== String(oldBounds);
            if (shouldSync) {
                this.syncPosition();
            }
        },
        lng: 'syncPosition',
        lat: 'syncPosition',
    },

    async mounted() {
        const Mapbox = await importMapboxGl();

        const map = new Mapbox.Map({
            container: this.$el,
            trackResize: true,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [this.lng, this.lat],
            zoom: this.zoom,
            bounds: this.bounds,
        });

        map.addControl(new Mapbox.NavigationControl({ showCompass: false }), 'bottom-left');
        map.dragRotate.disable();
        map.setMaxPitch(0);

        if (this.noZoomOnScroll) {
            map.scrollZoom.disable();
        }

        // This must be set before the map is accessed, otherwise it renders weird.
        // I think it has to do with extra properties Vue adds to make things reactive.
        this.map = map;

        map.on('moveend', event => {
            this.$emit('moveend', event);
        });

        map.on('load', () => {
            this.syncPosition();
            this.loaded = true;
        });
    },

    destroyed() {
        this.map?.remove();
        this.map = null;
    },

    methods: {
        syncPosition() {
            if (this.map) {
                if (this.bounds) {
                    this.map.fitBounds(this.bounds, {
                        animate: false,
                    });
                } else if (this.lng && this.lat) {
                    this.map.setCenter([this.lng, this.lat]);

                    if (this.zoom) {
                        this.map.setZoom(this.zoom);
                    }
                }
            }
        },

        autozoom(bbox) {
            if (this.autozoomState.timeout !== -1) {
                clearTimeout(this.autozoomState.timeout);
            }

            this.autozoomState.queue.push(bbox);

            this.autozoomState.timeout = setTimeout(async () => {
                const Mapbox = await importMapboxGl();
                const bounds = new Mapbox.LngLatBounds();
                for (let i = 0; i < this.autozoomState.queue.length; i += 1) {
                    bounds.extend(this.autozoomState.queue[i]);
                }
                this.autozoomState.queue = [];
                this.map?.fitBounds(bounds, { animate: false, padding: 20 });
            }, 50);
        },
    },
});
</script>

<style lang="postcss" scoped>
.map-base-root {
    height: 300px;
    position: relative;
}

.slot-hider {
    /* Slots will interact with the map directly, not render any elements. */
    display: none;
}
</style>
