
import Vue from '@/vueTyped';
import bbox from '@turf/bbox';
import type { GeoJSONSource, Map as MapboxMap, MapboxGeoJSONFeature, MapMouseEvent } from 'mapbox-gl';

export default Vue.extend({
    inject: ['parentMap'],

    props: {
        id: {
            type: String,
            default: () => Math.random().toString(36).split('.')[1],
        },

        data: {
            type: Object as () => MapboxGeoJSONFeature,
            required: true,
        },

        label: {
            type: String,
            default: '',
        },

        autozoom: {
            type: Boolean,
            default: false,
        },

        type: {
            type: String,
            default: 'line',
        },

        width: {
            type: Number,
            default: 3,
        },

        color: {
            type: String,
            default: '#888',
        },

        opacity: {
            type: Number,
            default: 1,
        },

        layout: {
            type: Object,
            default: () => ({}),
        }
    },

    computed: {
        map(): MapboxMap {
            // @ts-ignore
            return this.parentMap.map;
        },
    },

    watch: {
        data() {
            this.syncData();
        },
    },

    mounted() {
        this.setUp();
        if (!this.$vnode.key) {
            console.warn('Map components aren’t reactive, so they should have a Vue key');
        }
    },

    destroyed() {
        this.map.off('mousemove', this.handleMouseMove);
        this.map.off('click', this.handleClick);
        if (this.label) {
            this.map.removeLayer(`${this.id}-label`);
        }
        this.map.removeLayer(this.id);
        this.map.removeSource(this.id);
    },

    methods: {
        setUp() {
            if (!this.map) {
                console.error('Tried to set up MapShape without a map');
                return;
            }

            this.map.addSource(this.id, {
                type: 'geojson',
                data: this.data,
            });

            this.map.addLayer({
                id: this.id,
                type: this.type as 'line' | 'fill' | 'symbol',
                source: this.id,
                paint: (this.type === 'line' ? {
                    'line-color': this.color,
                    'line-opacity': this.opacity,
                    'line-width': this.width,
                } : this.type === 'fill' ? {
                    'fill-color': this.color,
                    'fill-opacity': this.opacity,
                } : {
                    'icon-opacity': this.opacity,
                }) as any,
                layout: this.type === 'symbol' ? this.layout : {},
            });

            // TODO: This label repeats inside polygons.
            // Find a center points and attach to that.
            if (this.label) {
                this.map.addLayer({
                    id: `${this.id}-label`,
                    type: 'symbol',
                    source: this.id,
                    paint: {
                        'text-color': 'black',
                        'text-halo-color': 'white',
                        'text-halo-width': 2,
                    },
                    layout: {
                        'text-field': this.label,
                        'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                    },
                });
            }

            this.syncData();

            if (this.$listeners.click) {
                this.map.on('mousemove', this.handleMouseMove);
                this.map.on('click', this.handleClick);
            }
        },

        handleMouseMove(event: MapMouseEvent) {
            const features = event.target.queryRenderedFeatures(event.point, { layers: [this.id] });
            event.target.getCanvas().style.cursor = features.length === 0 ? '' : 'pointer';
        },

        handleClick(event: MapMouseEvent) {
            const features = event.target.queryRenderedFeatures(event.point, { layers: [this.id] });
            this.$emit('click', { features, event });
        },

        async syncData() {
            const source = this.map.getSource(this.id) as GeoJSONSource | null | undefined;
            source?.setData(this.data);

            if (this.autozoom) {
                (this as any).parentMap.autozoom(bbox(this.data));
            }
        },
    },
});
