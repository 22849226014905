
import Vue from '@/vueTyped';

let instanceCount = 0;

export default Vue.extend({
  data() {
    return {
      id: instanceCount++,
      open: false,
    };
  },
});
